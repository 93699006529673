<template>
  <div>
    <b-card no-body header-tag="header" :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <b-row>
          <b-col cols="6" v-b-toggle="'collapse_' + table_no" style="cursor: pointer;">
            <template v-if="table_no !== undefined"> <strong>{{ table_no }} - </strong> </template>
            <template v-if="table_data.label">
              {{ table_data.label }}
            </template>
            <template v-else-if="table_data.name">
              {{ table_data.name }}
            </template>
            <template v-else-if="table_data.table_name">
              {{ table_data.table_name }}
            </template>
          </b-col>
          <b-col cols="6" style="text-align: right">
            <!--             
            <b-dropdown variant="success" size="md" style="margin-right: 10px;">
              <b-dropdown-item @click="f_convertSelectedTableToExcel(table_data.table)"> Excel olarak indir </b-dropdown-item>
            </b-dropdown>
            -->
            <b-button v-if="wdmr_data !== undefined" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11442')" @click="f_deleteTableFromWdmr()">
              <img src="@/icon/2960590.png" style="width: 1.5em;" />
            </b-button>
            <b-button v-if="wdm72_result_data !== undefined && wdm72_result_data.analyze_no !== undefined" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11443')" @click="f_addTableToArticle()">
              <img src="@/icon/2237824.png" style="width: 1.5em;" />
            </b-button>
            <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11444')" @click="f_convertSelectedTableToExcel(table_data.table)">
              <img src="@/icon/2237824.png" style="width: 1.5em;" />
            </b-button>
            <b-button size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 1.5em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse :id="'collapse_' + table_no" :visible="true">
        <b-row>
          <b-col cols="6">
            <b-form-input :placeholder="$t('wdm16.11445')" v-model="d_searchTextRow"></b-form-input>
          </b-col>
          <b-col cols="6">
            <b-form-input :placeholder="$t('wdm16.11446')" v-model="d_searchTextColumn"></b-form-input>
          </b-col>
        </b-row>
        <div :style="f_calculateTableStyle()">
          <table style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in table_data.table">
                <tr v-if="f_searchCrossRows(r, r_index, table_data.table.length)" :style="r_index % 2 === 0 ?'border: solid 1px black; background-color: #f6fcf5; line-height: 30px;' :'border: solid 1px black; line-height: 30px'">
                  <template v-for="(c, c_index) in r">
                    <td v-if="f_searchCrossColumns(c_index, table_data.table[0], r.length)" :style="f_calculateTableTdStyle(r_index, c_index)">
                      <template v-if="c.toString().length > 50">
                        {{ c.toString().substring(0, 50) }} <i style="color: red; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">......</i>
                      </template>
                      <template v-else>
                        {{ c }}
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </b-card>
    <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer :title="$t('wdm16.11447')" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import FiletransferService from '@/services/file_transfer';
import FileSaver from 'file-saver';
import { mapGetters } from 'vuex';
export default {
  name: 'WisdomTable',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {},
  created: function () {},
  props: {
    table_data: {
      type: Object,
      required: true
    },
    table_no: {
      type: Number,
      required: false
    },
    wdm72_result_data: {
      type: Object,
      required: false
    },
    mother_children: {
      type: Object,
      required: false
    },
    wdmr_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_searchRowTextTimer: { 'timeout': '', 'text': '' },
      d_searchColumnTextTimer: { 'timeout': '', 'text': '' },
      d_fullScreen: false,
      d_searchTextRow: '',
      d_searchTextColumn: '',
      d_showTableCellFullText: false,
      d_selectedTableCellFullText: ''
    };
  },
  methods: {
    f_showTableCellFullText: function (c) {
      this.d_selectedTableCellFullText = c.toString();
      this.d_showTableCellFullText = true;
    },
    f_addTableToArticle: function () {
      let table_name = '';
      if (this.table_data.table_name) {
        table_name = this.table_data.table_name;
      } else if (this.table_data.label) {
        table_name = this.table_data.label;
      } else if (this.table_data.name) {
        table_name = this.table_data.name;
      }
      this.mother_children.wdm72_result_data = {
        'analyze_no': this.wdm72_result_data.analyze_no,
        'wdm72_result_ind': this.wdm72_result_data.wdm72_result_ind,
        'table': this.table_data.table,
        'table_name': table_name
      };
      this.f_motherChildren('send_table_to_article');
    },
    f_motherChildren: function (opt) {
      if (this.mother_children[opt]) {
        this.mother_children[opt] = 0;
      } else {
        this.mother_children[opt] = 1;
      }
    },
    f_searchCrossRows: function (row, row_index, rows_length) {
      // if (this.d_searchTextRow && row_index !== 0 && row_index !== rows_length - 1) {
      if (this.d_searchTextRow && row_index !== 0) {
        let text_list = this.d_searchTextRow.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (row[0].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },
    f_searchCrossColumns: function (col_index, first_row, col_length) {
      // if (this.d_searchTextColumn && col_index !== 0 && col_index !== col_length - 1) {
      if (this.d_searchTextColumn && col_index !== 0) {
        let text_list = this.d_searchTextColumn.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (first_row[col_index].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },
    f_calculateTableStyle: function () {
      if (this.d_fullScreen) {
        let style = 'overflow-x: auto; height: ' + (window.innerHeight - 50).toString() + 'px;';
        return style;
      } else {
        return 'overflow-x: auto; height: 100%;';
      }
    },
    f_deleteTableFromWdmr: function () {
      let modal_data = { 'type': 'confirm', 'text': 'Tabloyu silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Tablo Silme İşlemi Hakkında', 'function_name': 'f_deleteWdmTableConfirmed', 'confirm': false, 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold;';
      }
      if (r_index === 0) {
        style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
      }
      if (c_index === 0) {
        style += 'position: sticky; left: 0;';
      }
      return style;
    },
    f_convertSelectedTableToExcel: function (table) {
      let data = { 'column_list': [], 'dataset': table };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excele aktarma işlemi yapılıyor. Lütfen bekleyiniz.' } });
      FiletransferService.convert_dataset_to_excel(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            FileSaver.saveAs(resp.data.result.file, "table.xlsx");
          } else {
            alert('errror:', resp.data.message);
          }
        })
    }
  },
  watch: {
    'd_searchColumnTextTimer.text': function () {
      clearTimeout(this.d_searchColumnTextTimer.timeout);
      let vm = this;
      this.d_searchColumnTextTimer.timeout = setTimeout(function () {
        vm.d_searchTextColumn = vm.d_searchColumnTextTimer.text;
      }, 1000);
    },
    'd_searchRowTextTimer.text': function () {
      clearTimeout(this.d_searchRowTextTimer.timeout);
      let vm = this;
      this.d_searchRowTextTimer.timeout = setTimeout(function () {
        vm.d_searchTextRow = vm.d_searchRowTextTimer.text;
      }, 1000);
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdmTableConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdmTableConfirmed(this.StoreModal.data.arguments);

        }
      }
    }
  }
};

</script>

<style type="text/css">
.normal-mode {
  margin-bottom: 3px;
}

.full-screen-mode {
  margin-bottom: 3px;
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

